/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import Navbar from "components/Navbar/Navbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Navbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>


            <Container>
              <Row className="row-grid align-items-center custom-row-min-height">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                     <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/ill/ill-2.svg").default}
                    /> 
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3">
                    <div>
                      <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                        <i className="ni ni-building text-primary" />
                      </div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">We bring ideas to life</h4>
                      <p className="text-white">
                        Ready to take on the world. With you.
                      </p>
                    </div>
                  </div>

                </Col>
              </Row>
            </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>



          <section className="section section-lg pt-lg-0 mt--200 ">
            <Container>
              <Row className="justify-content-center ">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 flex h-100">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            We are passionate about code
                          </h6>
                          <p className="description mt-3"> 
                            Combining passion and talent is a sure way to happy, beautiful code. Coding is our playground and canvas.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 flex h-100">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-istanbul" />
                          </div>
                          <h6 className="text-success text-uppercase">
                            We care about our end users
                          </h6>
                          <p className="description mt-3">
                            User experience is one of the most important aspects in an application, 
                            we take it seriously with solid research and metrics to find out what is best for users. 
                            A good application doesn't need any user manual.
                          </p>

                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0 flex h-100">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-warning rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-warning text-uppercase">
                            Be nimble. Am nimble.
                          </h6>
                          <p className="description mt-3">
                            The agile software engineering methodology values individual 
                            and interactions over processes and tools, customer collaboration 
                            over contract negotiation, 
                            and responding to change over following a plan
                          </p>

                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-1.png").default}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">

                    <h3>Omni Experience</h3>
                    <p>
                      We build awesome applications that are tailored with our users in mind. We are in their shoes to figure out the best way to interact and share with the "world," be it the powderhounds or the beauty-holics or the medical professionals.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-settings-gear-65" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Carefully crafted applications
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">Amazing ways to interact</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Happy users
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section" id="projects">
            <Container>

              <Row className="text-center justify-content-center pb-5">
                <Col lg="10">
                  <h2 className="display-3 ">Latest Projects</h2>

                </Col>
              </Row>

              <Row className="row-grid align-items-center d-flex align-items-stretch">
                <Col md="4">
                  <Card className="bg-primary shadow border-0 flex h-100">
                    <CardImg
                      alt="..."
                      src={require("assets/img/arcticspark/klassy.jpg").default}
                      top
                      width="200"
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        klazzy
                      </h4>
                      <p className="lead text-italic text-white">
                        A community enabling beauty-holics to share and find latest products and reviews.
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                {/*
                <Col md="4">
                  <Card className="bg-primary shadow border-0 flex h-100">
                    <CardImg
                      alt="..."
                      src={require("assets/img/arcticspark/snowaddict.jpg").default}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        snowaddict
                      </h4>
                      <p className="lead text-italic text-white">
                        Get ready for the next snowboard riding season. Find the best gear and reviews at snowaddict!
                      </p>
                    </blockquote>
                  </Card>
                </Col>
                */}
                <Col md="4">
                  <Card className="bg-primary shadow border-0 flex h-100">
                    <CardImg
                      alt="..."
                      src={require("assets/img/arcticspark/artviz.jpg").default}
                      top
                    />
                    <blockquote className="card-blockquote">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="svg-bg"
                        preserveAspectRatio="none"
                        viewBox="0 0 583 95"
                      >
                        <polygon
                          className="fill-default"
                          points="0,52 583,95 0,95"
                        />
                        <polygon
                          className="fill-default"
                          opacity=".2"
                          points="0,42 583,95 683,0 0,95"
                        />
                      </svg>
                      <h4 className="display-3 font-weight-bold text-white">
                        artviz
                      </h4>
                      <p className="lead text-italic text-white">
                        Analytic platform designed for art collectors and euthusiastics, containing information on art and latest art events
                      </p>
                    </blockquote>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

          <div id="about" >
          <Download />
          </div>



            <Container className="p-5">
              <Card className="bg-gradient-success shadow-lg border-">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Get in touch with us
                      </h3>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="mailto:info@arcticspark.co"
                        size="lg"
                      >
                        Email
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>


        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
